import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  lang: string;
  meta: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[];
  title: string;
  description: string;
  type?: string;
  author?: string;
}

const SEO: React.FC<SEOProps> = ({
  description,
  lang,
  meta,
  title,
  type,
  author,
}) => {
  meta.push(
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: !!type ? type : "website",
    },
    {
      property: "fb:app_id",
      content: "380193682914521",
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `twitter:creator`,
      content: !!author ? author : "@dgsplatform",
    },
    {
      name: `twitter:site`,
      content: "@dgsplatform",
    }
  );
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={description}
      meta={meta}
    />
  );
};

export default SEO;
